<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
        <div class="not-setting-tag" v-if="showTigs">暂未设置</div>
        <div>
          <a-form-model
              ref="rulesSystemForm"
              :model="systemSettingInfo"
              :rules="systemRules"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 16 }"
          >
            <a-row :gutter="20" type="flex" justify="center">
              <a-col :span="13">
                <a-form-model-item label="网站域名" prop="key">
                  <a-input v-model="systemSettingInfo.key" placeholder="请输入网站域名"/>
                </a-form-model-item>
                <a-form-model-item label="网站标题" prop="webTitle">
                  <a-input v-model="systemSettingInfo.webTitle" placeholder="请输入网站标题"/>
                </a-form-model-item>
                <a-form-model-item label="菜单标题" prop="menuTitle">
                  <a-input v-model="systemSettingInfo.menuTitle" placeholder="请输入菜单标题"/>
                </a-form-model-item>
                <a-form-model-item label="版权声明" prop="webCopyright">
                  <a-input v-model="systemSettingInfo.webCopyright" placeholder="请输入版权声明"/>
                </a-form-model-item>
                <a-form-model-item label="登录页面logo" prop="loginLogoAddress">
                  <a-upload-dragger
                      name="file"
                      :multiple="false"
                      :action="httpUploadSrc"
                      :showUploadList="false"
                      :beforeUpload="handleBeforeUpload"
                      @change="handleChange"
                  >
                    <div v-if="!isShowLogo">
                      <p class="ant-upload-drag-icon">
                        <a-icon type="inbox"/>
                      </p>
                      <p class="ant-upload-text">点击上传图片</p>
                    </div>
                    <div v-else>
                      <img v-if="systemSettingInfo.loginLogoAddressAll" :src="systemSettingInfo.loginLogoAddressAll"
                           width="100%"  style="padding: 5px" alt=""/>
                    </div>
                  </a-upload-dragger>
                </a-form-model-item>
                <a-form-model-item label="菜单logo" prop="menuLogoAddress">
                  <a-upload-dragger
                          name="file"
                          :multiple="false"
                          :action="httpUploadSrc"
                          :showUploadList="false"
                          :beforeUpload="handleBeforeUpload"
                          @change="menuLogoHandleChange"
                  >
                    <div v-if="!isShowLogo">
                      <p class="ant-upload-drag-icon">
                        <a-icon type="inbox"/>
                      </p>
                      <p class="ant-upload-text">点击上传图片</p>
                    </div>
                    <div v-else>

                      <img v-if="systemSettingInfo.menuLogoAddressAll" :src="systemSettingInfo.menuLogoAddressAll"
                           width="100%"  style="padding: 5px" alt=""/>
                    </div>
                  </a-upload-dragger>
                </a-form-model-item>
                <a-form-model-item label="操作" v-auth="'systemSetting-save'">
                  <a-button type="primary" @click="handleSave()">保存</a-button>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </el-card>

  </div>
</template>

<script>
export default {
  name: "SystemSetting",
  data() {
    return {
      showTigs:false,
      //图片上传地址
      httpUploadSrc: this._axios_env.SERVER+`/file/img?access_token=${localStorage.getItem('access_token').substring(6,localStorage.getItem('access_token').length)}`,
      //系统设置信息对象
      systemSettingInfo: {
        id: '',
        organizationId: '',
        key: '',
        webTitle: '',
        menuTitle: '',
        webCopyright: '',
        loginLogoAddress: '',
        menuLogoAddress: '',
        loginLogoAddressAll:'',
        menuLogoAddressAll:'',
      },
      //表单验证规则
      systemRules: {
        key: [
          {required: true, message: '请填写网站域名', trigger: 'blur'},
        ],
        webTitle: [
          {required: true, message: '请填写网站标题', trigger: 'blur'},
        ],
        menuTitle: [
          {required: true, message: '请填写菜单标题', trigger: 'blur'},
        ],
        webCopyright: [
          {required: true, message: '请填写版权声明', trigger: 'blur'},
        ],
        loginLogoAddress: [
          {required: true, message: '请上传logo', trigger: 'blur'},
        ],
      },
    }
  },

  computed: {
    //判断是否显示Logo
    isShowLogo() {
      if (this.systemSettingInfo.loginLogoAddress === "") {
        return false;
      } else {
        const reg = /null/;
        return !reg.test(this.systemSettingInfo.loginLogoAddress)
      }
    }
  },

  mounted() {
    this.getCurrentWebSetting()
  },

  methods: {
    //获取当前网站设置
    async getCurrentWebSetting() {
      const {data: res} = await this.http.pageSetting.getWebSetting();
      if (res.status === 200) {
        if(res.data!=null){
          this.systemSettingInfo = res.data;
          if(this.systemSettingInfo.id === ''){
            this.showTigs = true;
          }
        }else{
          this.showTigs = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },

    //上传文件之前的钩子
    handleBeforeUpload(file, fileList) {
      const reg = /\.(png|jpg|jpeg|gif)$/ig;
      const filename = file.name;
      if (reg.test(filename) === false) {
        this.$message.error('请上传正确的图片格式！');
        return false;
      }
    },

    //上传文件改变时的状态
    handleChange({file, fileList, event}) {
      if (file['response'] !== undefined) {
        this.systemSettingInfo.loginLogoAddressAll = file['response'].data.allPath;
        this.systemSettingInfo.loginLogoAddress = file['response'].data.path;
      }
    },
    menuLogoHandleChange({file, fileList, event}) {
      if (file['response'] !== undefined) {
        this.systemSettingInfo.menuLogoAddressAll = file['response'].data.allPath;
        this.systemSettingInfo.menuLogoAddress = file['response'].data.path;
      }
    },

    //保存
    handleSave() {
      this.$refs.rulesSystemForm.validate(async valid => {
        if (valid) {
          const {data: res} = await this.http.pageSetting.createOrUpdateWebSetting(this.systemSettingInfo);
          if (res.status === 200) {
            this.$message.success(res.msg);
            this.getCurrentWebSetting()
          } else {
            this.$message.error(res.msg);
          }
        } else {
          return false
        }
      });
    },
  },
}
</script>

<style scoped>
.sys-save-btn {
  margin-right: 10%;
}
.not-setting-tag {
  position: relative;
  width: 85px;
  text-align: center;
  background-color: #909090;
  color: #FFF;
  border-bottom-right-radius: 2px;
}
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
    overflow-y: auto;
  }
}
</style>
